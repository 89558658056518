





















import { defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    participant: {
      type: Object,
      required: true,
      default: "",
    },
  },

  setup(props, { root, emit }) {
    const model = reactive({
      nfcTag: null,
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
    });

    const closeDialog = () => {
      emit("close-dialog");
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("panel.event.participant.assignNfc.409")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const removeCard = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .put(
          `participant/${props.participant.id}/unassign-nfc-tag`,
          {},
          {
            headers: {
              event: root.$route.params.id,
            },
          }
        )
        .then(() => {
          state.success = true;
          state.error = false;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc(
              "panel.event.participant.assignNfc.removeSuccess"
            ),
          });
          emit("close-dialog");
          emit("fetch");
        })
        .catch((error) => {
          state.error = error.response.status;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return { model, state, removeCard, closeDialog };
  },
});
